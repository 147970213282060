@import 'tailwindcss/base';

@import 'tailwindcss/components';
@import '../../../../libs/ui/src/index.css';

@import 'tailwindcss/utilities';

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }
}

.bc-xss p {
  @apply mb-4;
}

.bc-xss p:last-child {
  @apply mb-0;
}
